export const GET_SUBSUBSUBCATEGORYS_STATED = "GET_SUBSUBSUBCATEGORYS_STATED";
export const GET_SUBSUBSUBCATEGORYS = "GET_SUBSUBSUBCATEGORYS";
export const GET_SUBSUBSUBCATEGORYS_ENDED = "GET_SUBSUBSUBCATEGORYS_ENDED";
export const ADD_SUBSUBSUBCATEGORY_STATED = "ADD_SUBSUBSUBCATEGORY_STARTED";
export const ADD_SUBSUBSUBCATEGORY = "ADD_SUBSUBSUBCATEGORY";
export const ADD_SUBSUBSUBCATEGORY_ENDED = "ADD_SUBSUBSUBCATEGORY_ENDED";
export const EDIT_SUBSUBSUBCATEGORY_STATED = "EDIT_SUBSUBSUBCATEGORY_STATED";
export const EDIT_SUBSUBSUBCATEGORY = "EDIT_SUBSUBSUBCATEGORY";
export const EDIT_SUBSUBSUBCATEGORY_ENDED = "EDIT_SUBSUBSUBCATEGORY_ENDED";
export const GET_SUBSUBSUBCATEGORY = "GET_SUBSUBSUBCATEGORY";
export const GET_SUBSUBSUBCATEGORY_STATED = "GET_SUBSUBSUBCATEGORY_STATED";
export const GET_SUBSUBSUBCATEGORY_ENDED = "GET_SUBSUBSUBCATEGORY_ENDED";
export const RESET_SUBSUBSUBCATEGORY = "RESET_SUBSUBSUBCATEGORY";
export const ERROR_SUBSUBSUBCATEGORY = "ERROR_SUBSUBSUBCATEGORY";
export const GET_ALL_SUBSUBSUBCATEGORYS_STATED = "GET_ALL_SUBSUBSUBCATEGORYS_STATED";
export const GET_ALL_SUBSUBSUBCATEGORYS = "GET_ALL_SUBSUBSUBCATEGORYS";
export const GET_ALL_SUBSUBSUBCATEGORYS_ENDED = "GET_ALL_SUBSUBSUBCATEGORYS_ENDED";
