export const GET_SUBSUBCATEGORYS_STATED = "GET_SUBSUBCATEGORYS_STATED";
export const GET_SUBSUBCATEGORYS = "GET_SUBSUBCATEGORYS";
export const GET_SUBSUBCATEGORYS_ENDED = "GET_SUBSUBCATEGORYS_ENDED";
export const ADD_SUBSUBCATEGORY_STATED = "ADD_SUBSUBCATEGORY_STARTED";
export const ADD_SUBSUBCATEGORY = "ADD_SUBSUBCATEGORY";
export const ADD_SUBSUBCATEGORY_ENDED = "ADD_SUBSUBCATEGORY_ENDED";
export const EDIT_SUBSUBCATEGORY_STATED = "EDIT_SUBSUBCATEGORY_STATED";
export const EDIT_SUBSUBCATEGORY = "EDIT_SUBSUBCATEGORY";
export const EDIT_SUBSUBCATEGORY_ENDED = "EDIT_SUBSUBCATEGORY_ENDED";
export const GET_SUBSUBCATEGORY = "GET_SUBSUBCATEGORY";
export const GET_SUBSUBCATEGORY_STATED = "GET_SUBSUBCATEGORY_STATED";
export const GET_SUBSUBCATEGORY_ENDED = "GET_SUBSUBCATEGORY_ENDED";
export const RESET_SUBSUBCATEGORY = "RESET_SUBSUBCATEGORY";
export const ERROR_SUBSUBCATEGORY = "ERROR_SUBSUBCATEGORY";
export const GET_ALL_SUBSUBCATEGORYS_STATED = "GET_ALL_SUBSUBCATEGORYS_STATED";
export const GET_ALL_SUBSUBCATEGORYS = "GET_ALL_SUBSUBCATEGORYS";
export const GET_ALL_SUBSUBCATEGORYS_ENDED = "GET_ALL_SUBSUBCATEGORYS_ENDED";
