export const GET_FRANCHISES_STATED = "GET_FRANCHISES_STATED";
export const GET_FRANCHISES = "GET_FRANCHISES";
export const GET_FRANCHISES_ENDED = "GET_FRANCHISES_ENDED";
export const ADD_FRANCHISE_STATED = "ADD_FRANCHISE_STARTED";
export const ADD_FRANCHISE = "ADD_FRANCHISE";
export const ADD_FRANCHISE_ENDED = "ADD_FRANCHISE_ENDED";
export const EDIT_FRANCHISE_STATED = "EDIT_FRANCHISE_STATED";
export const EDIT_FRANCHISE = "EDIT_FRANCHISE";
export const EDIT_FRANCHISE_ENDED = "EDIT_FRANCHISE_ENDED";
export const GET_FRANCHISE = "GET_FRANCHISE";
export const GET_FRANCHISE_STATED = "GET_FRANCHISE_STATED";
export const GET_FRANCHISE_ENDED = "GET_FRANCHISE_ENDED";
export const RESET_FRANCHISE = "RESET_FRANCHISE";
export const ERROR_FRANCHISE = "ERROR_FRANCHISE";
export const GET_ALL_FRANCHISES_STATED = "GET_ALL_FRANCHISES_STATED";
export const GET_ALL_FRANCHISES = "GET_ALL_FRANCHISES";
export const GET_ALL_FRANCHISES_ENDED = "GET_ALL_FRANCHISES_ENDED";
