export const GET_TEMPLATES_STATED = "GET_TEMPLATES_STATED";
export const GET_TEMPLATES = "GET_TEMPLATES";
export const GET_TEMPLATES_ENDED = "GET_TEMPLATES_ENDED";
export const ADD_TEMPLATE_STATED = "ADD_TEMPLATE_STARTED";
export const ADD_TEMPLATE = "ADD_TEMPLATE";
export const ADD_TEMPLATE_ENDED = "ADD_TEMPLATE_ENDED";
export const EDIT_TEMPLATE_STATED = "EDIT_TEMPLATE_STATED";
export const EDIT_TEMPLATE = "EDIT_TEMPLATE";
export const EDIT_TEMPLATE_ENDED = "EDIT_TEMPLATE_ENDED";
export const GET_TEMPLATE = "GET_TEMPLATE";
export const GET_TEMPLATE_STATED = "GET_TEMPLATE_STATED";
export const GET_TEMPLATE_ENDED = "GET_TEMPLATE_ENDED";
export const RESET_TEMPLATE = "RESET_TEMPLATE";
export const ERROR_TEMPLATE = "ERROR_TEMPLATE";
export const GET_ALL_TEMPLATES_STATED = "GET_ALL_TEMPLATES_STATED";
export const GET_ALL_TEMPLATES = "GET_ALL_TEMPLATES";
export const GET_ALL_TEMPLATES_ENDED = "GET_ALL_TEMPLATES_ENDED";
