export const GET_COLLECTIONS_STATED = "GET_COLLECTIONS_STATED";
export const GET_COLLECTIONS = "GET_COLLECTIONS";
export const GET_COLLECTIONS_ENDED = "GET_COLLECTIONS_ENDED";
export const ADD_COLLECTION_STATED = "ADD_COLLECTION_STARTED";
export const ADD_COLLECTION = "ADD_COLLECTION";
export const ADD_COLLECTION_ENDED = "ADD_COLLECTION_ENDED";
export const EDIT_COLLECTION_STATED = "EDIT_COLLECTION_STATED";
export const EDIT_COLLECTION = "EDIT_COLLECTION";
export const EDIT_COLLECTION_ENDED = "EDIT_COLLECTION_ENDED";
export const GET_COLLECTION = "GET_COLLECTION";
export const GET_COLLECTION_STATED = "GET_COLLECTION_STATED";
export const GET_COLLECTION_ENDED = "GET_COLLECTION_ENDED";
export const RESET_COLLECTION = "RESET_COLLECTION";
export const ERROR_COLLECTION = "ERROR_COLLECTION";
export const GET_ALL_COLLECTIONS_STATED = "GET_ALL_COLLECTIONS_STATED";
export const GET_ALL_COLLECTIONS = "GET_ALL_COLLECTIONS";
export const GET_ALL_COLLECTIONS_ENDED = "GET_ALL_COLLECTIONS_ENDED";
