export const GET_MOBILEBANNERS_STATED = "GET_MOBILEBANNERS_STATED";
export const GET_MOBILEBANNERS = "GET_MOBILEBANNERS";
export const GET_MOBILEBANNERS_ENDED = "GET_MOBILEBANNERS_ENDED";
export const ADD_MOBILEBANNER_STATED = "ADD_MOBILEBANNER_STARTED";
export const ADD_MOBILEBANNER = "ADD_MOBILEBANNER";
export const ADD_MOBILEBANNER_ENDED = "ADD_MOBILEBANNER_ENDED";
export const EDIT_MOBILEBANNER_STATED = "EDIT_MOBILEBANNER_STATED";
export const EDIT_MOBILEBANNER = "EDIT_MOBILEBANNER";
export const EDIT_MOBILEBANNER_ENDED = "EDIT_MOBILEBANNER_ENDED";
export const GET_MOBILEBANNER = "GET_MOBILEBANNER";
export const GET_MOBILEBANNER_STATED = "GET_MOBILEBANNER_STATED";
export const GET_MOBILEBANNER_ENDED = "GET_MOBILEBANNER_ENDED";
export const RESET_MOBILEBANNER = "RESET_MOBILEBANNER";
export const ERROR_MOBILEBANNER = "ERROR_MOBILEBANNER";
export const GET_ALL_MOBILEBANNERS_STATED = "GET_ALL_MOBILEBANNERS_STATED";
export const GET_ALL_MOBILEBANNERS = "GET_ALL_MOBILEBANNERS";
export const GET_ALL_MOBILEBANNERS_ENDED = "GET_ALL_MOBILEBANNERS_ENDED";
