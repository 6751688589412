export const GET_VARIATIONS_STATED = "GET_VARIATIONS_STATED";
export const GET_VARIATIONS = "GET_VARIATIONS";
export const GET_VARIATIONS_ENDED = "GET_VARIATIONS_ENDED";
export const ADD_VARIATION_STATED = "ADD_VARIATION_STARTED";
export const ADD_VARIATION = "ADD_VARIATION";
export const ADD_VARIATION_ENDED = "ADD_VARIATION_ENDED";
export const EDIT_VARIATION_STATED = "EDIT_VARIATION_STATED";
export const EDIT_VARIATION = "EDIT_VARIATION";
export const EDIT_VARIATION_ENDED = "EDIT_VARIATION_ENDED";
export const GET_VARIATION = "GET_VARIATION";
export const GET_VARIATION_STATED = "GET_VARIATION_STATED";
export const GET_VARIATION_ENDED = "GET_VARIATION_ENDED";
export const RESET_VARIATION = "RESET_VARIATION";
export const ERROR_VARIATION = "ERROR_VARIATION";
export const GET_ALL_VARIATIONS_STATED = "GET_ALL_VARIATIONS_STATED";
export const GET_ALL_VARIATIONS = "GET_ALL_VARIATIONS";
export const GET_ALL_VARIATIONS_ENDED = "GET_ALL_VARIATIONS_ENDED";
