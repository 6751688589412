export const GET_NOTIFICATIONS_STATED = "GET_NOTIFICATIONS_STATED";
export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS";
export const GET_NOTIFICATIONS_ENDED = "GET_NOTIFICATIONS_ENDED";
export const ADD_NOTIFICATION_STATED = "ADD_NOTIFICATION_STARTED";
export const ADD_NOTIFICATION = "ADD_NOTIFICATION";
export const ADD_NOTIFICATION_ENDED = "ADD_NOTIFICATION_ENDED";
export const EDIT_NOTIFICATION_STATED = "EDIT_NOTIFICATION_STATED";
export const EDIT_NOTIFICATION = "EDIT_NOTIFICATION";
export const EDIT_NOTIFICATION_ENDED = "EDIT_NOTIFICATION_ENDED";
export const GET_NOTIFICATION = "GET_NOTIFICATION";
export const GET_NOTIFICATION_STATED = "GET_NOTIFICATION_STATED";
export const GET_NOTIFICATION_ENDED = "GET_NOTIFICATION_ENDED";
export const RESET_NOTIFICATION = "RESET_NOTIFICATION";
export const ERROR_NOTIFICATION = "ERROR_NOTIFICATION";
export const GET_ALL_NOTIFICATIONS_STATED = "GET_ALL_NOTIFICATIONS_STATED";
export const GET_ALL_NOTIFICATIONS = "GET_ALL_NOTIFICATIONS";
export const GET_ALL_NOTIFICATIONS_ENDED = "GET_ALL_NOTIFICATIONS_ENDED";
