export const GET_REVIEWS_STATED = "GET_REVIEWS_STATED";
export const GET_REVIEWS = "GET_REVIEWS";
export const GET_REVIEWS_ENDED = "GET_REVIEWS_ENDED";
export const ADD_REVIEW_STATED = "ADD_REVIEW_STARTED";
export const ADD_REVIEW = "ADD_REVIEW";
export const ADD_REVIEW_ENDED = "ADD_REVIEW_ENDED";
export const EDIT_REVIEW_STATED = "EDIT_REVIEW_STATED";
export const EDIT_REVIEW = "EDIT_REVIEW";
export const EDIT_REVIEW_ENDED = "EDIT_REVIEW_ENDED";
export const GET_REVIEW = "GET_REVIEW";
export const GET_REVIEW_STATED = "GET_REVIEW_STATED";
export const GET_REVIEW_ENDED = "GET_REVIEW_ENDED";
export const RESET_REVIEW = "RESET_REVIEW";
export const ERROR_REVIEW = "ERROR_REVIEW";
export const GET_ALL_REVIEWS_STATED = "GET_ALL_REVIEWS_STATED";
export const GET_ALL_REVIEWS = "GET_ALL_REVIEWS";
export const GET_ALL_REVIEWS_ENDED = "GET_ALL_REVIEWS_ENDED";
