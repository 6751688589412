import React, { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import FormComponents from '../common/FormComponents';
import moment from 'moment';
import VariableOptions from './VariableOptions';
import VariationTable from './VariationTable';
import { CheckBox, SelectBox, TextInput } from '../Form/Form';
import CommissionForm from './CommissionForm';
import { useSelector, useDispatch } from 'react-redux';
import {
  useAllVendors,
  useGetDropdownOptions,
} from '../../shared/hooks/UseVendor';
import { useLoadUser } from '../../shared/hooks/UseAuth';

function ProductForm({
  data,
  edit,
  submitForm,
  setFeaturedImage,
  setGallery,
  inputFields,
  initialValues,
  dropdown_options,
  loadOptions,
}) {
  const auth_data = useSelector((state) => state.auth);

  let history = useHistory();
  let newInitialValues = {
    name: '',
    collections: [],
    product_category: '',
    description: '',
    media: [],
    regular_price: '',
    sale_price: '',
    tax: '',
    sku: '',
    in_stock: true,
    stock: 100,
    weight: '',
    length: '',
    width: '',
    height: '',
    featured: false,
    is_variable_product: true,
    variation_attrs: [
      {
        label: '',
        options: [],
      },
    ],
    variations: [],
  };

  const [vendor_data, setPageNumber, deleteBtnClicked] = useAllVendors();
  const { vendors_loading, vendors, total_vendors, page, pages } = vendor_data;
  console.log('Vendors -', vendors);

  const [user_data] = useLoadUser();
  const { user } = user_data;
  console.log('USER -', user);
  return (
    <div className='card-body'>
      {edit ? (
        <div className='row'>
          <Formik
            initialValues={{
              name: data.name,
              slug: data.slug,
              product_status: data.product_status,
              vendor: data.vendor && data.vendor._id,
              collections: data.collections,
              product_category: data.product_category
                ? data.product_category._id
                : '',
              sub_category: data.sub_category ? data.sub_category : '',
              sub_sub_category: data.sub_sub_category
                ? data.sub_sub_category
                : '',
              commission: data.product_category
                ? data.product_category.commission
                : '',
              description: data.description,
              media: data.media,
              featured: data.featured,
              published_date: data.published_date
                ? moment(data.published_date).format('YYYY-MM-DD')
                : '',
              regular_price: data.regular_price,
              sale_price: data.sale_price,
              tax: data.tax,
              sku: data.sku,
              in_stock: data.in_stock,
              stock: data.stock,
              weight: data.weight,
              length: data.length,
              width: data.width,
              height: data.height,
              is_variable_product: data.is_variable_product,
              variation_attrs: data.variation_attrs,
              variations: data.variations,
            }}
            validationSchema={Yup.object({
              name: Yup.string().required('Required'),
              collections: Yup.array().required('Required').min(1),
              product_category: Yup.string().required('Required'),
              regular_price: Yup.number()
                .positive('Numbers Only')
                .required('Required'),
              sale_price: Yup.number()
                .positive('Numbers Only')
                .required('Required'),

              weight: Yup.number().positive('Numbers Only'),
              length: Yup.number().positive('Numbers Only'),
              height: Yup.number().positive('Numbers Only'),
              width: Yup.number().positive('Numbers Only'),
              stock: Yup.number().positive('Numbers Only'),
            })}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              setSubmitting(true);
              await submitForm(values);
              setSubmitting(false);
              resetForm(true);
            }}
          >
            {(formik) => {
              console.log(formik.values);
              return (
                <Form>
                  <FormComponents
                    formik={formik}
                    inputFields={inputFields}
                    setFeaturedImage={setFeaturedImage}
                    dropdown_options={dropdown_options}
                    setGallery={setGallery}
                    edit={edit}
                    loadOptions={loadOptions}
                  />

                  {user && user.role === 'SUPER ADMIN' ? (
                    <div className='row'>
                      <div className='col-md-6'>
                        <CheckBox label='Featured' name='featured'></CheckBox>
                      </div>
                      <div className='col-md-6'>
                        <TextInput
                          label='Published Date'
                          name='published_date'
                          type='date'
                        ></TextInput>
                      </div>
                      <div className='col-md-6'>
                        <SelectBox
                          label={'product_status'}
                          name='product_status'
                        >
                          <option value=''></option>
                          <option value='Active'>Active</option>
                          <option value='Pending'>Pending</option>
                          <option value='Rejected'>Rejected</option>
                        </SelectBox>
                      </div>
                    </div>
                  ) : null}

                  <CommissionForm
                    formik={formik}
                    dropdown_options={dropdown_options}
                  />
                  {formik.values.is_variable_product && (
                    <>
                      <VariableOptions
                        formik={formik}
                        item='variation_attrs'
                        inputFields={inputFields}
                      />
                      <VariationTable formik={formik} item='variations' />
                    </>
                  )}
                  <div className='row'>
                    <div className='col-md-12 text-center m-3'>
                      <button type='submit' className='btn btn-success'>
                        {formik.isSubmitting
                          ? 'Processing...'
                          : edit
                          ? 'Save & Continue'
                          : 'Save'}
                      </button>
                      <a
                        className='btn btn-secondary m-3'
                        onClick={history.goBack}
                        href='#goback'
                      >
                        <i className='fa fa-angle-left'></i> Go Back
                      </a>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      ) : (
        <div className='row'>
          <Formik
            initialValues={newInitialValues}
            validationSchema={Yup.object({
              name: Yup.string().required('Required'),
              product_category: Yup.string().required('Required'),
              collections: Yup.array().required('Required').min(1),
              regular_price: Yup.number()
                .positive('Numbers Only')
                .required('Required'),
              sale_price: Yup.number()
                .positive('Numbers Only')
                .required('Required'),
              tax: Yup.number().positive('Numbers Only'),
              weight: Yup.number().positive('Numbers Only'),
              length: Yup.number().positive('Numbers Only'),
              height: Yup.number().positive('Numbers Only'),
              width: Yup.number().positive('Numbers Only'),
              stock: Yup.number().positive('Numbers Only'),
            })}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              setSubmitting(true);
              await submitForm(values);
              setSubmitting(false);
              resetForm(true);
            }}
          >
            {(formik) => {
              return (
                <Form>
                  <FormComponents
                    formik={formik}
                    inputFields={inputFields}
                    setFeaturedImage={setFeaturedImage}
                    dropdown_options={dropdown_options}
                    setGallery={setGallery}
                    edit={edit}
                    loadOptions={loadOptions}
                  />

                  {user && user.role === 'SUPER ADMIN' ? (
                    <div className='row'>
                      <div className='col-md-6'>
                        <CheckBox label='Featured' name='featured'></CheckBox>
                      </div>
                      <div className='col-md-6'>
                        <TextInput
                          label='Published Date'
                          name='published_date'
                          type='date'
                        ></TextInput>
                      </div>
                      <div className='col-md-6'>
                        <SelectBox
                          label={'Product Status '}
                          name='product_status'
                        >
                          <option value=''></option>
                          <option value='Active'>Active</option>
                          <option value='Pending'>Pending</option>
                          <option value='Rejected'>Rejected</option>
                        </SelectBox>
                      </div>
                    </div>
                  ) : null}

                  <CommissionForm
                    formik={formik}
                    dropdown_options={dropdown_options}
                  />

                  {formik.values.is_variable_product && (
                    <>
                      <VariableOptions
                        formik={formik}
                        item='variation_attrs'
                        inputFields={inputFields}
                      />
                      <VariationTable formik={formik} item='variations' />
                    </>
                  )}
                  <div className='row'>
                    <div className='col-md-12 text-center m-3'>
                      <button type='submit' className='btn btn-success'>
                        {formik.isSubmitting
                          ? 'Processing...'
                          : edit
                          ? 'Edit'
                          : 'Save'}
                      </button>
                      <a
                        className='btn btn-secondary m-3'
                        onClick={history.goBack}
                        href='#goback'
                      >
                        <i className='fa fa-angle-left'></i> Go Back
                      </a>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      )}
    </div>
  );
}

export default ProductForm;
