export const GET_SUBSUBSUBSUBCATEGORYS_STATED = "GET_SUBSUBSUBSUBCATEGORYS_STATED";
export const GET_SUBSUBSUBSUBCATEGORYS = "GET_SUBSUBSUBSUBCATEGORYS";
export const GET_SUBSUBSUBSUBCATEGORYS_ENDED = "GET_SUBSUBSUBSUBCATEGORYS_ENDED";
export const ADD_SUBSUBSUBSUBCATEGORY_STATED = "ADD_SUBSUBSUBSUBCATEGORY_STARTED";
export const ADD_SUBSUBSUBSUBCATEGORY = "ADD_SUBSUBSUBSUBCATEGORY";
export const ADD_SUBSUBSUBSUBCATEGORY_ENDED = "ADD_SUBSUBSUBSUBCATEGORY_ENDED";
export const EDIT_SUBSUBSUBSUBCATEGORY_STATED = "EDIT_SUBSUBSUBSUBCATEGORY_STATED";
export const EDIT_SUBSUBSUBSUBCATEGORY = "EDIT_SUBSUBSUBSUBCATEGORY";
export const EDIT_SUBSUBSUBSUBCATEGORY_ENDED = "EDIT_SUBSUBSUBSUBCATEGORY_ENDED";
export const GET_SUBSUBSUBSUBCATEGORY = "GET_SUBSUBSUBSUBCATEGORY";
export const GET_SUBSUBSUBSUBCATEGORY_STATED = "GET_SUBSUBSUBSUBCATEGORY_STATED";
export const GET_SUBSUBSUBSUBCATEGORY_ENDED = "GET_SUBSUBSUBSUBCATEGORY_ENDED";
export const RESET_SUBSUBSUBSUBCATEGORY = "RESET_SUBSUBSUBSUBCATEGORY";
export const ERROR_SUBSUBSUBSUBCATEGORY = "ERROR_SUBSUBSUBSUBCATEGORY";
export const GET_ALL_SUBSUBSUBSUBCATEGORYS_STATED = "GET_ALL_SUBSUBSUBSUBCATEGORYS_STATED";
export const GET_ALL_SUBSUBSUBSUBCATEGORYS = "GET_ALL_SUBSUBSUBSUBCATEGORYS";
export const GET_ALL_SUBSUBSUBSUBCATEGORYS_ENDED = "GET_ALL_SUBSUBSUBSUBCATEGORYS_ENDED";
