export const GET_SUBCATEGORYS_STATED = "GET_SUBCATEGORYS_STATED";
export const GET_SUBCATEGORYS = "GET_SUBCATEGORYS";
export const GET_SUBCATEGORYS_ENDED = "GET_SUBCATEGORYS_ENDED";
export const ADD_SUBCATEGORY_STATED = "ADD_SUBCATEGORY_STARTED";
export const ADD_SUBCATEGORY = "ADD_SUBCATEGORY";
export const ADD_SUBCATEGORY_ENDED = "ADD_SUBCATEGORY_ENDED";
export const EDIT_SUBCATEGORY_STATED = "EDIT_SUBCATEGORY_STATED";
export const EDIT_SUBCATEGORY = "EDIT_SUBCATEGORY";
export const EDIT_SUBCATEGORY_ENDED = "EDIT_SUBCATEGORY_ENDED";
export const GET_SUBCATEGORY = "GET_SUBCATEGORY";
export const GET_SUBCATEGORY_STATED = "GET_SUBCATEGORY_STATED";
export const GET_SUBCATEGORY_ENDED = "GET_SUBCATEGORY_ENDED";
export const RESET_SUBCATEGORY = "RESET_SUBCATEGORY";
export const ERROR_SUBCATEGORY = "ERROR_SUBCATEGORY";
export const GET_ALL_SUBCATEGORYS_STATED = "GET_ALL_SUBCATEGORYS_STATED";
export const GET_ALL_SUBCATEGORYS = "GET_ALL_SUBCATEGORYS";
export const GET_ALL_SUBCATEGORYS_ENDED = "GET_ALL_SUBCATEGORYS_ENDED";
