export const GET_COLORS_STATED = "GET_COLORS_STATED";
export const GET_COLORS = "GET_COLORS";
export const GET_COLORS_ENDED = "GET_COLORS_ENDED";
export const ADD_COLOR_STATED = "ADD_COLOR_STARTED";
export const ADD_COLOR = "ADD_COLOR";
export const ADD_COLOR_ENDED = "ADD_COLOR_ENDED";
export const EDIT_COLOR_STATED = "EDIT_COLOR_STATED";
export const EDIT_COLOR = "EDIT_COLOR";
export const EDIT_COLOR_ENDED = "EDIT_COLOR_ENDED";
export const GET_COLOR = "GET_COLOR";
export const GET_COLOR_STATED = "GET_COLOR_STATED";
export const GET_COLOR_ENDED = "GET_COLOR_ENDED";
export const RESET_COLOR = "RESET_COLOR";
export const ERROR_COLOR = "ERROR_COLOR";
export const GET_ALL_COLORS_STATED = "GET_ALL_COLORS_STATED";
export const GET_ALL_COLORS = "GET_ALL_COLORS";
export const GET_ALL_COLORS_ENDED = "GET_ALL_COLORS_ENDED";
