export const GET_FRAMES_STATED = "GET_FRAMES_STATED";
export const GET_FRAMES = "GET_FRAMES";
export const GET_FRAMES_ENDED = "GET_FRAMES_ENDED";
export const ADD_FRAME_STATED = "ADD_FRAME_STARTED";
export const ADD_FRAME = "ADD_FRAME";
export const ADD_FRAME_ENDED = "ADD_FRAME_ENDED";
export const EDIT_FRAME_STATED = "EDIT_FRAME_STATED";
export const EDIT_FRAME = "EDIT_FRAME";
export const EDIT_FRAME_ENDED = "EDIT_FRAME_ENDED";
export const GET_FRAME = "GET_FRAME";
export const GET_FRAME_STATED = "GET_FRAME_STATED";
export const GET_FRAME_ENDED = "GET_FRAME_ENDED";
export const RESET_FRAME = "RESET_FRAME";
export const ERROR_FRAME = "ERROR_FRAME";
export const GET_ALL_FRAMES_STATED = "GET_ALL_FRAMES_STATED";
export const GET_ALL_FRAMES = "GET_ALL_FRAMES";
export const GET_ALL_FRAMES_ENDED = "GET_ALL_FRAMES_ENDED";
